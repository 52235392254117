<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
        <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
      </div>
      <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
        <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Añade votos</p>
        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-2 px-6">
            <img class="w-5 h-5" :src="info" alt="">
            <p class="font-semibold text-xs">Solo se te cobrará si tu canción gana.</p>
          </div>
          <div class="flex flex-col gap-4 px-6">
            <VotePricing v-for="(votePlan, index) in votePlans" :isLoading="isLoading === index" :key="index" :plan="votePlan.plan" :price="votePlan.price" :votes="votePlan.votes" @click="handleSubmitVotePlan(votePlan, index)"/>
            <div class="slider-container" style="margin-bottom: 20px;">
              <AmountSlider :min="1" :max="sliderMax" :step="1" @update:value="handleSliderUpdate" @redirect="handleRedirect" />
            </div>          
          </div>  
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/black-close-icon.png';
import info from '@/assets/images/info-icon.png';
import VotePricing from './VotePricing.vue';
import AmountSlider from './AmountSlider.vue';

export default {
  name: 'AddVotesModal',
  props: {
    isAddSong: Boolean,
    votePlans: Object,
    isLoading: Boolean
  },
  emits: ['onCloseModal', 'submitVotePlan'],
  components: {
    VotePricing,
    AmountSlider
  },
  setup(props, { emit }) {
    const handleCloseModal = () => {
      emit('onCloseModal');
    };
    const handleSubmitVotePlan = (votePlan) => {
      emit('submitVotePlan', votePlan.price);
    }
    const handleRedirect = (value) => {
      emit('submitVotePlan', value);
    };
    return {
      close,
      info,
      handleCloseModal,
      handleSubmitVotePlan,
      handleRedirect
    }
  }
};
</script>
