<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
        <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
      </div>
      <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-tp-black rounded-2xl px-6 py-10 gap-8">
        <img class="w-26 h-26" :src="tick" alt="">
        <div class="flex flex-col items-center gap-4">
          <p class="font-bold text-center text-xl" v-if="promocode">¡Has aplicado el código promocional!</p>
          <p class="font-bold text-center text-xl" v-else>¡Has usado tu voto gratis!</p>
          <button class="bg-tp-yellow text-tp-black font-bold text-md px-5 py-2 rounded-xl" @click="handleCloseModal">Continúa con TuParty</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/black-close-icon.png';
import tick from '@/assets/images/tick-icon-circle.png';

export default {
  name: 'ConfirmFreeVoteModal',
  props: {
    promocode: {
      type: Boolean,
      default: null
    }
  },
  setup(props, { emit }) {
    

    const handleCloseModal = () => {
      emit('onCloseModal');
    }

    return {
      close,
      tick,
      handleCloseModal,
    }
  }
};
</script>
